<template>
  <div v-loading.fullscreen.lock="loading"></div>
</template>

<script>
import "@/service/Company";
import AuthService from "@/service/oidcService";
import { HTTP } from "@/service/axios";
import jwt from "jsonwebtoken";
export default {
  data() {
    return {
      loading: true,
      data: null,
      verifyToken: null
    };
  },
  computed: {
    useroidc() {
      return this.$store.state.useroidc;
    }
  },
  methods: {
    async autoSugnin() {
      try {
        AuthService.signinRedirect();
      } catch {}
    },
    async getUserProfile() {
      var result = await AuthService.getUser()
        .then(e => {
          return e;
        })
        .catch(e => {
          console.log("Error signinRedirectCallback : ", e);
        });
      this.$store.commit("SET_USER_OIDC", result);
    }
  },
  async mounted() {
    try {
      this.autoSugnin();
    } catch (e) {
      console.log("mounted :", e);
    }
  }
};
</script>